import React, { lazy, Suspense } from "react";
import RouteFallback from "./fallback.routes";
const B2bDashboardPage = lazy(() => import("../pages/B2bDashboard.page"));
const B2bShipmentPage = lazy(() => import("../pages/B2bShipment.page"));
const B2bCalculatePage = lazy(() => import("../pages/B2bCalculatorPage"));
const PickuppointsPage = lazy(() => import("../pages/Pickuppoints.page"));
const BillingPage = lazy(() => import("../pages/Billing.page"));
const SettingsPage = lazy(() => import("../pages/Settings.page"));
const B2bRemittance = lazy(() => import("../pages/B2bRemittance.page"));
const WeightDiscrepancy = lazy(() => import("../pages/WeightDiscrepancy.page"));
const B2bShippingPartnersPage = lazy(() =>
  import("../pages/B2bShipmentPartnePage")
);
const StatusTrackPage = lazy(() =>import("../pages/AWBstatus.page"));
const B2bRoutesConfig = {
  privateRoute: [
    {
      path: "",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="private">
          <B2bDashboardPage />
        </Suspense>
      ),
    },
    {
      path: "/dashboard",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="dashboard">
          <B2bDashboardPage />
        </Suspense>
      ),
    },
    {
      path: "/shipmentstatus",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="shipmentstatus">
          <B2bShipmentPage />
        </Suspense>
      ),
    },
    {
      path: "/shippingpartners",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="B2bShippingPartnersPage">
          <B2bShippingPartnersPage />
        </Suspense>
      ),
    },
    {
      path: "/pickuppoints",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="pickuppoints">
          <PickuppointsPage />
        </Suspense>
      ),
    },
    {
      path: "calculatePrice",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="calculatePrice">
          <B2bCalculatePage />
        </Suspense>
      ),
    },

    {
      path: "/billing",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="billing">
          <BillingPage />
        </Suspense>
      ),
    },

    {
      path: "/settings",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="settings">
          <SettingsPage />
        </Suspense>
      ),
    },
    {
      path: "/weightmanagement",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="weightmanagement">
          <WeightDiscrepancy />
        </Suspense>
      ),
    },
    {
      path: "/remittance",
      exact: true,
      restricted: true,
      element: (
        <Suspense fallback={<RouteFallback />} key="remittance">
          <B2bRemittance />
        </Suspense>
      ),
    },
    {
      path: "/status/:awbNumber",
      layoutType: false,
      exact: true,
      restricted: false,
      element: (
        <Suspense fallback={<RouteFallback />} key="awbstatus">
          <StatusTrackPage />
        </Suspense>
      ),
    },
  ],
};

export default B2bRoutesConfig;
